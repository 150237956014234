// MainLayout.js
import React from "react";
import ImageGallery from "react-image-gallery";
import ss01 from "../img/ss01.jpeg";
import ss02 from "../img/ss02.jpeg";
import ss03 from "../img/ss03.jpeg";
import ss04 from "../img/ss04.jpeg";
import appleBadge from "../img/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg";
import googleBadge from "../img/GetItOnGooglePlay_Badge_Web_color_English.png";
import { Link } from "react-router-dom";

const images = [
  {
    original: ss01,
    thumbnail: ss01,
  },
  {
    original: ss02,
    thumbnail: ss02,
  },
  {
    original: ss03,
    thumbnail: ss03,
  },
  {
    original: ss04,
    thumbnail: ss04,
  },
];

const appleLink =
  "https://apps.apple.com/app/apple-store/id6499045389?pt=127040445&ct=Product%20Page&mt=8";
const googleLink =
  "https://play.google.com/store/apps/details?id=com.ertanarmutcu.OneCollector&referrer=utm_source%3Dgoogle%26utm_medium%3DBanner%26utm_campaign%3DProduct%2520Page";

const HomePage = () => {
  return (
    <div className="App">
      <div className="App-overlay"> </div>
      <div className="App-header">
        <div className="Title-container">
          <h1 className="App-title">OneCollector</h1>
          <h2 className="subtitle">
            Set sail and dive into the world of collectible cards with
            OneCollector, the perfect app for OPTCG fans!
          </h2>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <a
              href={appleLink}
              className="App-button"
              style={{ marginBottom: "10px" }} // Optional: Adds space between the badges
            >
              <img src={appleBadge} alt="Download on the App Store" />
            </a>
            <a href={googleLink} className="App-button">
              <img src={googleBadge} alt="Download on the Play Store" />
            </a>
          </div>
        </div>
      </div>
      <div className="App-body">
        <ImageGallery items={images} showPlayButton={false} />
      </div>
      <footer className="App-footer">
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/terms">Terms & Conditions</Link>
      </footer>
    </div>
  );
};

export default HomePage;
