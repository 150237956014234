import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PrivacyContent } from "./PrivacyContent";
import { TermsContent } from "./TermsContent";

function Legal({ type }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (type === "privacy") {
      setContent(PrivacyContent);
    }
    if (type === "terms") {
      setContent(TermsContent);
    }
  }, [type]);

  return (
    <div className="App">
      <div className="App-overlay"> </div>
      <div
        className="legal-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

Legal.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Legal;
